import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { pageview } from '../utils/scripts';
import Loader from '../components/loader';

const NotFoundPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    pageview();
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <Layout>
      <SEO title="404: Not found" />
      <section className="">
        <div className="container py-12 mx-auto flex flex-wrap items-center">
          <div className="w-full text-center">
            <div className="mb-1">
              {loading && <Loader />}
              {!loading && (
                <>
                  <h2 className="text-xl text-gray-700 font-headline">Uh oh!</h2>
                  <p className="mb-8 leading-relaxed">The content you are trying to reach cannot be found.</p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default NotFoundPage
